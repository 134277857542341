import React, {useContext, useEffect} from "react";
import {Helmet} from "react-helmet";
import {ParametersContext} from "../../contexts/ParametersContext";
import {useAPI} from "../../hooks/APIHook";
import {useDispatch} from "react-redux";
import {notification} from "../../common/reducer/NotificationReducer";

export const DonateComponent = () => {
    const {isMobile} = useContext(ParametersContext);
    const {request} = useAPI();
    const dispatch = useDispatch();

    useEffect(() => {
        changeReceive().then()
    }, []);

    async function changeReceive() {
        const nickname = document.getElementById("nickname").value;
        const sum = document.getElementById("sum").value;
        const promocode = document.getElementById("promocode").value || "none";

        const fSum = (await request(`https://api.diamondworld.pro/price/calculate?nickname=${encodeURIComponent(nickname)}&promocode=${encodeURIComponent(promocode)}&sum=${sum}&bonus=1`)).sum || 0;

        document.getElementById("receive").value = `${fSum}₽`;
    }

    function blockPattern(e, pattern) {
        if (pattern.test(e.key) && e.key !== "Backspace" && !e.key.includes("Arrow")) e.preventDefault();

        setTimeout(async () => {
            e.target.value = e.target.value.replace(pattern, "");

            await changeReceive();
        }, 100);
    }

    function blockNickname(e) {
        blockPattern(e, /\W/);
    }

    function blockPromocode(e) {
        blockPattern(e, /[\W_]/);
    }

    function blockAmount(e) {
        blockPattern(e, /[^0-9]/);
    }

    function submit(e) {
        const form = document.querySelectorAll("form")[0];
        form.action = `https://api.diamondworld.pro/${e.target.id}/create`;

        if (e.target.id === "freekassa" || e.target.id === "freekassa2")
            if (form.elements[2].value < 50) {
                e.preventDefault();
                dispatch(notification({message: "Минимальная сумма платежа: 50 рублей"}));
            }

            if (e.target.id === "freekassacards")
                if (form.elements[2].value < 10) {
                    e.preventDefault();
                    dispatch(notification({message: "Минимальная сумма платежа: 10 рублей"}));
                }
    }

    return (
        <>
            <Helmet title="Пополнение баланса < DiamondWorld"/>
            <div className="donate">
                {isMobile ? <></> :
                    <div className="information">
                        <div>
                            DiamondWorld<br/>
                            Minecraft сервер
                        </div>
                        <div>
                            Здесь вы можете пополнить свой баланс, который используется для покупки товаров на сервере
                        </div>
                    </div>
                }

                <div>
                    <div>
                        <div>Бонусы!</div>
                        Пополнение от 500р - бонус 5%<br/>
                        Пополнение от 3000р - бонус 10%<br/>
                        <br/>
                        При проблемах с пополнением через VISA попробуйте карту MASTERCARD или другой способ оплаты<br/>
                        <br/>
                        Для пополнения через украинскую карту создайте заявку <a href="https://t.me/PayMentsDW_bot"
                                                                               target="_blank"
                                                                               rel="noreferrer">в боте</a> (график работы менеджера бота с 10:00 до 22:00 МСК).<br/>
                    </div>

                    <form>
                        <div className="input-group">
                            <label htmlFor="nickname">Никнейм:</label>
                            <input name="account" id="nickname" placeholder="Введите ник" type="text" required
                                   onKeyDown={blockNickname}/>
                        </div>

                        <div className="input-group">
                            <label htmlFor="promocode">Промокод:</label>
                            <small>Большие буквы кода - обязательны!</small>
                            <input name="promocode" id="promocode" placeholder="Введите промокод (если есть)"
                                   type="text" onKeyDown={blockPromocode}/>
                        </div>

                        <div className="input-flex">
                            <div className="input-group">
                                <label htmlFor="sum">Потратите:</label>
                                <input name="sum" id="sum" type="text" placeholder="Сумма" defaultValue="500" required
                                       onKeyDown={blockAmount}/>
                            </div>
                            <div className="input-group">
                                <label htmlFor="receive">Получите:</label>
                                <input id="receive" type="text" placeholder="500₽" disabled/>
                            </div>
                        </div>

                        <button className="btn" id="enot" type="submit" onClick={submit}>Оплата СБП (без комиссии)</button>
                        <button className="btn" id="freekassacards" type="submit" onClick={submit}>Оплата картой РФ</button>
                        <button className="btn" id="freekassa" type="submit" onClick={submit}>Оплата другим способом</button>
                    </form>
                </div>
            </div>
        </>
    );
}
