import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { ParametersContext } from "./contexts/ParametersContext";

import { useRoutes } from "./routes";

import { MenuComponent } from "./components/menu/MenuComponent";
import NotificationComponent from "./components/common/NotificationComponent";

export const App = () => {
    const routes = useRoutes();

    const [isMobile, setMobile] = useState(window.innerWidth <= 1024);
    useEffect(() => {
        window.addEventListener("resize", () => {
            const isMobile = window.innerWidth <= 1024;

            setMobile(isMobile);
        });
    }, []);

    return (
        <BrowserRouter>
            <NotificationComponent/>

            <ParametersContext.Provider value={{ isMobile }}>
                <aside className="shadow"/>

                <header className="animation-in">
                    <MenuComponent/>
                </header>

                <main className="animated">
                    { routes }
                </main>

                <footer>
                    © 2017-{ new Date().getFullYear() } DIAMONDWORLD.PRO ВСЕ ПРАВА ЗАЩИЩЕНЫ!
                    <div style={{fontSize: 10}}>GAMETTI LTD, Registration No. 14348659, 2nd Floor, College House, 17 King Edwards Road, Ruislip, London</div>
                </footer>
            </ParametersContext.Provider>
        </BrowserRouter>
    );
}
